:root {
  --bg-color: #ffffff;
  --txt-color: #171717;
  --txt-color-light: #ffffff;
  --accent-color: #0f0f0f;
  --accent2-color: #dddddd;
  --inactive-color: #d3d3d3;
  --mid-color: #f7f7f7;
  --dark-color: #ffffff;
}

body {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: var(--bg-color) !important;
}

/* ########## PLACE SECTION ########## */

.section1 {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: var(--bg-color); /* Optional: Set a background color */
  padding: 10px 10px 0px 10px; /* Optional: Add some padding */
}

#logo {
  width: 110px;
  padding: 0px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.magazi {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 300;
  text-align: center;
  color: var(--txt-color);
  /*   justify-content: center;
 */
}

.clearhere {
  clear: left;
}

.menu-btn {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin: 15px 0px 0px 10px;
  padding: 03px 0px 0px 0px;
  position: absolute;
  left: 0;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--txt-color-light);
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  box-shadow: 0;
}

embed {
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

.menupdf {
  height: 100%;
}

.menubtn {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin: 25px 0px 0px 20px;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
}

/* ########## ITEMS SECTION ########## */

.list {
  text-align: left;
  max-width: 800px;
  font-size: 16px;
  font-weight: 400;
}

.list-group {
  box-shadow: 0px;
  border-width: 0px;
  border-radius: 0px;
  border: 0px solid #000000;
  padding-bottom: 40vh;
}

.items-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 50;
  pointer-events: none;
}

.list-group.disabled {
  pointer-events: none;
  overflow: hidden;
}

.list-group-chooseThing {
  box-shadow: 0px;
  border-width: 0px;
  border-radius: 0px;
  border: 0px solid #000000;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}

.list-group-item {
  border-top: 0px solid #000000;
  border-bottom: 0px solid var(--txt-color);
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  background-color: var(--mid-color);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3px;
  color: var(--txt-color);
}

.list-group-item-chooseThing {
  list-style: none;
  border-top: 0px solid #000000;
  border-bottom: 0px solid var(--txt-color);
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  background-color: var(--mid-color);
  padding: 7px 0px 10px 20px;
  margin-top: 3px;
  color: var(--txt-color);
}

.list-group-item:last-child {
  border-top: 0px solid #000000;
  border-bottom: 0px solid #000000;
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--txt-color);
}

.inlinediv {
  display: inline;
}

.paid-li {
  padding-left: 40px;
  background-color: var(--inactive-color);
}

.itemsinthelist {
  float: left;
}

.prices {
  text-align: right;
}

/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none; /* Removes default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1.5px solid #555;
  border-radius: 2px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Checkmark (hidden by default) */
input[type="checkbox"]::before {
  content: "✓";
  font-size: 16px;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

/* Change background when checked */
input[type="checkbox"]:checked {
  background: #000000;
  border-color: #000000;
}

/* Show checkmark when checked */
input[type="checkbox"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

/* ########## PAYMENT SECTION ########## */

.arxikiSection3 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg-color);
  border-top: 0px solid #000000;
  margin: 0px 0px;
  padding: 15px 5px 12px 5px;
  /* transition: height 0.3s ease; */
  z-index: 55;
}

.firstpage {
  padding: 40px 05px 20px 05px;
  border-top: 1.6px solid #000000;
}

hr {
  display: block;
  width: 25%;
  height: 1px;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 75%);
  margin: 1px 0px 1px auto;
  padding: 0;
}

.toPayAmUnderRem {
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: var(--txt-color);
}

.nooneHasPaid {
  color: var(--txt-color);
  font-size: 13px;
  font-weight: 200;
}

.btnpay {
  margin: 0px auto 0px auto !important;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  padding: 12px 40px 12px 40px;
  border-color: black;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnpay1stpage {
  margin: 60px auto 0px auto !important;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  padding: 14px 40px 14px 40px;
  border-color: black;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnpay2 {
  margin: 7px auto 0px auto !important;
  background-color: var(--bg-color);
  color: var(--txt-color);
  /* color: #ccd4ff; */
  padding: 14px 40px 14px 40px;
  border: 2px solid;
  border-color: var(--accent-color);
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnbk {
  margin: 0px 20px 15px auto !important;
  padding: auto;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  border: 1px solid #ffffff;
  width: 90px;
  font-size: 16px;
  height: 45px;
  font-weight: 300;
  /*  display: flex; */
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.back-icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  margin-bottom: 2px;
  filter: invert(1); /* This will make the black icon white */
}

.tipTitle {
  text-align: left;
  font-size: 15px;
}

.tipz {
  margin: 8px 0px 18px 0px;
  width: 100%;
  padding: 0px 0px 0px 0px;
  text-align: center;
  border: 0px solid var(--accent-color);
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.tip {
  font-size: 13px;
  background-color: var(--bg-color);
  color: var(--txt-color);
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
}

.tip.active {
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  border: 1px solid var(--accent-color);
  border-radius: 2px;
}

.tipz button {
  margin: 0px;
  flex-grow: 1;
  flex-basis: 0;
  width: auto;
  padding: 0;
  height: 50px;
}

.noTip {
  width: 40px !important;
}

.disableTip {
  visibility: collapse;
}

.tipz .tipform {
  text-align: center;
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  flex-basis: 0;
  width: auto;
  height: 100%;
  border: 0px solid var(--accent2-color);
  background-color: var(--bg-color);
  border-radius: 0px;
  font-size: 14px;
  box-shadow: none !important;
  box-sizing: border-box;
}

.tipz .tipform input {
  border: 0px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 13px;
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
}

input::placeholder {
  font-weight: 200;
  color: var(--txt-color);
}

.form1 {
  border: 0px;
  margin: 0px 0 0px 0;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
}

.form2 {
  border: 0;
  width: 100%;

  margin: 0px auto 20px auto;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
}

.form2 input {
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
  width: 100%;
}

.customAmount {
  margin: 10px 0 0 0;
}

.customAmountField {
  border: 0px;
  margin: 6px 0 0px 0;
  padding: 0px 0px 0px 16px;
  box-shadow: none !important;

  height: 50px;
  text-align: left;
}

.euroInInput {
  margin-left: -2em;
  margin-right: 10px;
  color: var(--txt-color-light);
}

.euroInInputSmall {
  margin-left: -1.1em;
  margin-right: 5px;
  color: var(--txt-color-light);
}

/* HIDE ARROWS IN INPUT FIELD */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  display: inline-block;
}

.totalsContainer {
  margin: 0 15px -52px 0;
  padding: 0px 0 05px 0;
}

.total {
  text-align: right;
  padding-left: 0px;
  padding-top: 0px;
  font-weight: 500;
}

.totalinvaded {
  text-align: right;
  padding: 0px 0 0 0;
  font-weight: 400;
  color: #b8b8b8;
}

.remaining {
  text-align: right;
  font-weight: 400;
  font-size: 16px;
}

.tipAfterTotal {
  text-align: right;
  font-size: 16px;
}

.input-number {
  width: 50px !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: 25px !important;
  height: 40px !important;
  border: none !important;
  border-bottom: solid 2px #ccc !important;
  box-shadow: none !important;
  position: relative;
}
.input-container {
  position: relative;
  height: 40px;
  display: block;
  margin: 0 15px;
}
.input-container:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 10px;
  background-color: #ccc !important;
  z-index: 10;
}
.input-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 10px;
  background-color: #ccc !important;
  z-index: 10;
}
.btn-number {
  border-radius: 50% !important;
  background-color: #ccc !important;
  color: #fff !important;
  outline: none !important;
  font-size: 25px !important;
  padding: 3px !important;
  width: 40px;
  height: 40px;
}

.division {
  margin: 15px 0 20px 0;
}

.counter {
  width: 300px;
  margin: auto;
  border-radius: 5px;
  padding: 0px;
  text-align: center;
}
.counter h1 {
  margin: 0;
  padding: 0px 0 8px 0;
  font-size: 14px;
  font-weight: normal;
}

.highlight {
  font-size: 17px;
  font-weight: 600;
}

.counter button {
  background: var(--accent-color);
  border: 0;
  padding: 10px;
  margin: 0px 4px 0 5px;
  width: 45px;
  height: 45px;
  outline: none;
  border-radius: 100px;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
}

.c1 {
  margin-bottom: 10px;
}

.c1 button {
  background: var(--accent-color);
  border: 0;
  padding: 10px;
  margin: 0px 4px 0 5px;
  width: 55px;
  height: 55px;
  outline: none;
  border-radius: 100px;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
}

/*  PAID ORDER  */

.paidOrder {
  text-align: center;
  margin: 80px 0 40px 0;
  font-size: 14px;
}

/* ############### MODAL CSS ################# */

/* Prevent scrolling */
.no-scroll {
  overflow: hidden;
}

.modal {
  position: fixed;
  width: 100%;
  height: 85%;
  background: var(--bg-color);
  margin: 0;
  padding: 0;
}

.modal-main {
  position: fixed;
  background: white;
  width: 100%;
  height: auto;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  margin: 0;
  padding: 20px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.CloseButton {
  width: 100px;
  height: 40px;
  padding: 0 0 0 0;
  margin: 0 0 20px 0;
}

/* ############### STRIPE CSS ############### */
#root {
  display: flex;
  align-items: center;
}

.body_stripe {
  /*de kanei tpt gia tin wra, itan sketo body*/
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

.payment-form form {
  display: inline-block;
  margin: 0;
  width: 94vw;

  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: hsl(0, 0%, 0%);
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  /* display: block; */
  /* transition: all 0.2s ease; */
  /* box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07); */
  width: 100%;
}

/* button:hover {
  filter: contrast(115%);
} */

button:disabled {
  opacity: 0.3;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* @media only screen and (max-width: 600px) {
  .payment-form {
    width: 80vw;
    min-width: initial;
  }
} */

/* ################ SUCCESS PAYMENT PAGE ################ */

.successPayment {
  margin: 40px 0 0 0;
  text-align: center;
  color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.return-button {
  background-color: var(--accent-color);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.return-button:hover {
  background-color: #000000;
}

/* ################ 404 PAGE ################ */

.page404 {
  text-align: center;
}

/* ################ CLIENT DASHBOARD ################ */

/* Login Page Styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--bg-color);
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
}

.login-box {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--txt-color);
  font-size: 24px;
  font-weight: 600;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: var(--txt-color);
  font-size: 14px;
  font-weight: 500;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.login-button {
  background-color: var(--accent-color);
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #000000;
}

.login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-error {
  background-color: #fff2f2;
  color: #ff4444;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

/* Dashboard Styles */
.dashboard-container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.dashboard-sidebar {
  width: 250px;
  background-color: var(--accent-color);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}

.sidebar-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.sidebar-nav {
  flex: 1;
  margin-top: 20px;
}

.nav-item {
  width: 100%;
  padding: 12px;
  margin: 5px 0;
  background: none;
  border: none;
  color: white;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-item i {
  margin-right: 10px;
  width: 20px;
}

.logout-button {
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.dashboard-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
}

.dashboard-header {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
}

.order-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.status {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
}

.status.paid {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status.pending {
  background-color: #fff3e0;
  color: #ef6c00;
}

.order-details p {
  margin: 8px 0;
  color: #666;
}

.analytics-placeholder,
.settings-placeholder {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.settings-section {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.settings-description {
  color: #666;
  margin-bottom: 20px;
}

.tip-settings {
  margin-top: 20px;
}

.tip-input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}

.tip-input-group label {
  width: 120px;
  color: #333;
}

.percentage-input {
  display: flex;
  align-items: center;
  gap: 5px;
}

.percentage-input input {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.percentage-input span {
  color: #666;
}

.settings-actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.save-button,
.edit-button {
  background-color: var(--accent-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: #666;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.current-tips {
  margin-bottom: 20px;
  color: #333;
}

.update-message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.update-message.success {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.update-message.error {
  background-color: #ffebee;
  color: #c62828;
}

.tip-container {
  margin: 15px 0;
}

.tip-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tip-button {
  padding: 8px 16px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  background: white;
  color: var(--accent-color);
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  min-width: 60px;
}

.tip-button.selected {
  background: var(--accent-color);
  color: white;
}

.custom-tip-input {
  position: relative;
  flex: 1;
  min-width: 80px;
}

.custom-tip-input input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  text-align: right;
}

.custom-tip-input input.has-value {
  background: var(--accent-color);
  color: white;
}

.currency-symbol {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: inherit;
}

.filter-section {
  display: flex;
  justify-content: center;
  width: 33.33%;
  margin: 0;
  background: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.filter-button {
  padding: 8px 16px;
  background: transparent;
  border: none;
  color: var(--txt-color);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 6px;
  font-weight: 500;
  flex: 1;
  position: relative;
  z-index: 1;
}

.filter-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

.filter-button.active {
  color: white;
}

.filter-button:focus {
  outline: none;
}

.active-background {
  position: absolute;
  height: calc(100% - 10px);
  width: calc(25% - 2px);
  background: var(--accent-color);
  border-radius: 6px;
  transition: transform 0.3s ease;
  top: 5px;
  left: 5px;
}

.period-total {
  font-size: 14px;
  margin-left: auto;
  padding: 8px 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-weight: 500;
  color: var(--accent-color);
}

.header-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
